<template>

  <div class="widget statistic-widget">

    <div class="widget-header">
      <div class="widget-header-label">
        <h3>{{ title }}</h3>
        <info-box :field="infoBox" />
      </div>
      <div class="widget-header-toolbar">
        <div class="actions">
          <!-- <div class="btn btn-grey btn-bold ml-3"><i class="fas fa-download"></i></div> -->
        </div>
      </div>
    </div>
    <div class="widget-body no-padding">

      <table class="vuetable" @copy="handleTableCopy">
        <thead>
          <tr>
            <th width="35%">{{ title }}</th>
            <th v-for="label in labels" :key="label">{{ label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ title }}</td>
            <td v-for="year in years" :key="year.key">
                {{ year == 0 ? '' : year.toLocaleString('sv-SE') }}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import moment from 'moment';
import InfoBox from '@/components/InfoBox';

export default {
  data() {
    return {
      moment: moment,
      excludedYears: this.getDefaultExcludedYears(), // Initially exclude last 5 years
    };
  },
  props: {
    data: Array,
    title: String,
    yearsSelected: Array,
    infoBox: String,
  },
  components: {
    InfoBox,
  },
  computed: {
    labels() {
      let labels = [];
      this.data.forEach((value) => {
        if (
          !labels.includes(value.key) &&
          !this.excludedYears.includes(value.key) // Exclude the years
        ) {
          if (this.yearsSelected.length) {
            if (this.yearsSelected.includes(value.key)) {
              labels.push(value.key);
            }
          } else {
            labels.push(value.key);
          }
        }
      });

      return labels;
    },
    years() {
      let labels = [];
      this.data.forEach((value) => {
        if (
          !labels.includes(value.key) &&
          !this.excludedYears.includes(value.key) // Exclude the years
        ) {
          if (this.yearsSelected.length) {
            if (this.yearsSelected.includes(value.key)) {
              labels.push(value.doc_count);
            }
          } else {
            labels.push(value.doc_count);
          }
        }
      });

      return labels;
    },
  },
  watch: {
    yearsSelected(newYears) {
      if (newYears.length > 0) {
        this.excludedYears = []; // Do not exclude anything if a selection is made
      } else {
        this.excludedYears = this.getDefaultExcludedYears(); // Apply default exclusion
      }
    }
  },
  methods: {
    capitalize(word) {
      return word.trim().replace(/^\w/, (c) => c.toUpperCase());
    },
    handleTableCopy(event) {
      // Prevent default copying behavior
      event.preventDefault();

      // Create a new string to store the copied content
      let copiedContent = '';

      // Get the selected rows and iterate over them
      const selection = window.getSelection();
      const selectedRows = selection.toString().split('\n');

      selectedRows.forEach((row) => {
        let rowData = '';

        // Split the row into cells
        const cells = row.split('\t');

        cells.forEach((cell) => {
          // Check if the cell is a number (check if it contains space)
          if (/\d+\s\d+/.test(cell)) {
            // This is a number, so remove the spaces before copying
            rowData += cell.replace(/\s+/g, '') + '\t';
          } else {
            // It's a text or unformatted cell, so copy as-is
            rowData += cell + '\t';
          }
        });

        // Add the row data to the copied content
        copiedContent += rowData.trim() + '\n';
      });

      // Set the new content to the clipboard
      event.clipboardData.setData('text/plain', copiedContent.trim());
    },
    getDefaultExcludedYears() {
      return ['2017', '2018', '2019', '2020']; //Array.from({ length: cutoffYear - 2017 + 1 }, (_, i) => (2017 + i).toString());
    }
  },
};
</script>